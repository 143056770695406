import type { ReactNode, MouseEventHandler } from 'react';
import Link from "next/link";
import styles from "./link-button.module.scss";
const LinkButton = ({
  text,
  href,
  rightIcon,
  leftIcon,
  onClick,
  ...remainingProps
}: IProps) => {
  return <Link {...remainingProps} href={href} className={styles.linkButton} onClick={onClick} data-sentry-element="Link" data-sentry-component="LinkButton" data-sentry-source-file="index.tsx">
			{leftIcon}
			{text}
			{rightIcon}
		</Link>;
};
interface IProps {
  href: string;
  leftIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>; // Add the onClick prop to the interface
  rel?: string;
  rightIcon?: ReactNode;
  target?: string;
  text?: string;
  prefetch?: boolean;
}
export default LinkButton;